// core
import React from "react";
import { useTranslation } from "react-i18next";

// utils

// components
import ProofExtendedCard from "../../../components/atoms/ProofExtendedCard";

// styles
import "./index.scss";

const ReservesFunds = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="reserves-funds">
      <div className="app__wrapper">
        <div className="reserves-funds__grid">
          <div className="reserves-funds__content">
            <h2 className="reserves-funds__title">{data.title}</h2>

            <div
              className="reserves-funds__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            {data?.proofs?.data.length > 0 && (
              <div className="reserves-funds__proofs">
                <div className="reserves-funds__proofs-title">
                  {t("pages.reserves.proofTitle")}
                </div>
                <ul className="reserves-funds__proofs-list">
                  {data.proofs.data.map((item, index) => (
                    <li key={index}>
                      <ProofExtendedCard
                        isDark={true}
                        className="reserves-funds__proofs-card"
                        img={item.icon}
                        title={item.title}
                        amount={item.amount}
                        type={"link"}
                        linkTitle={item.address}
                        link={item.url}
                        isShowNote={false}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div
            className="reserves-funds__img"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <img src={data.image} alt={data.title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservesFunds;
